import React from 'react';

function OnboardReauth() {
    const handleRegisterClick = async () => {
        window.location.href = 'https://pandaanalytics.nl/onboard_franchisee'; 
      };
  return (
    <div className='flex flex-col items-center justify-center min-h-screen text-white text-4xl'>
      <h1>Please reauthenticate to continue.</h1>
      <button className='mt-4 text-lg rounded-full border-2 border-white px-8 py-1' onClick={handleRegisterClick}>Back to register</button>
    </div>
  );
}

export default OnboardReauth;