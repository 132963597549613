import React from 'react';

function OnboardSuccess() {
  return (
    // create a page with titile : Register and a button register
    <div className='flex flex-col items-center justify-center min-h-screen text-white text-4xl'>
      <h1>Congratulations! You're onboarded.</h1>
    </div>
  );
}

export default OnboardSuccess;