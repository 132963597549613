import React from 'react';

function OnboardFranchisee() {
  const handleRegisterClick = async () => {
    try {
      const stripe = require('stripe')('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');

      const account = await stripe.accounts.create({
        type: 'standard'
      });

      const accountLink = await stripe.accountLinks.create({
        account: account.id,
        refresh_url: 'https://pandaanalytics.nl/onboard_reauth',
        return_url: 'https://pandaanalytics.nl/onboard_success',
        type: 'account_onboarding',
      });

      // Redirect to the account link URL
      window.location.href = accountLink.url;

    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className='flex flex-col items-center justify-center min-h-screen text-white text-4xl'>
      <h1>Onboard Franchisee</h1>
      <button className='mt-4 text-lg rounded-full border-2 border-white px-8 py-1' onClick={handleRegisterClick}>Register</button>
    </div>
  );
}

export default OnboardFranchisee;