import React from 'react';

function DeleteCocoAppAccount() {
    return (
        <div className='h-screen flex flex-col relative bg-black'>
            <div className='text-white p-4'>
                <h2 className='text-2xl font-bold mb-4'>Account Deletion for CoCo Tea NL</h2>

                <h3 className='text-xl font-bold mb-2'>1. In-App Deletion:</h3>
                <ul className='list-disc pl-0 mb-4 flex flex-col items-center'>
                    <li className='mb-2'>Open the CoCo Tea NL app.</li>
                    <li className='mb-2'>Navigate to your profile.</li>
                    <li className='mb-2'>Select "Edit Profile."</li>
                    <li className='mb-2'>Find and click on the "Delete Your Account" button.</li>
                </ul>

                <h3 className='text-xl font-bold mb-2'>2. Request Deletion via Support:</h3>
                <ul className='list-disc pl-0 mb-4 flex flex-col items-center'>
                    <li className='mb-2'>Alternatively, you can send a deletion request through the app.</li>
                    <li className='mb-2'>Go to your profile.</li>
                    <li className='mb-2'>Choose "Support & Help."</li>
                    <li className='mb-2'>Click on "Issue Others."</li>
                    <li className='mb-2'>Provide a description requesting the deletion of your account.</li>
                </ul>

                <h3 className='text-xl font-bold mb-2'>Types of Deleted or Kept Data:</h3>
                <ul className='list-disc pl-0 mb-4 flex flex-col items-center'>
                    <li className='mb-2'>When you delete your CoCo Tea NL account, the following data will be deleted:</li>
                    <li className='mb-2'>Profile information.</li>
                    <li className='mb-2'>Usage history.</li>
                    <li className='mb-2'>Preferences and settings.</li>
                </ul>

                <h3 className='text-xl font-bold mb-2'>Retention Period:</h3>
                <p className='mb-4 text-center'>Deleted data will be kept for a maximum of 7 days for statistical analysis purposes.</p>

                <p className='mb-4 text-center'>For any assistance or queries, contact our support team at info@panda-analytics.nl.</p>
                
                <p className='mb-4 text-center'>Thank you for being a CoCo Tea NL user!</p>
            </div>
        </div>
    );
}

export default DeleteCocoAppAccount;
